<template>
  <div class="course-manager" v-if="!loading">
    <div class="header">Course manager</div>
    <div class="selector">
      <section>
        <h5>View or create a course layout</h5>
        <div
          class="course-selector"
          :class="{ expanded: showCourseDropdown }"
          @click.stop="showCourseDropdown = !showCourseDropdown"
          v-click-outside="closeCourseSelector"
        >
          <p>{{ selectedCourseNameFormatted }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.414"
            height="8.121"
            viewBox="0 0 13.414 8.121"
            class="collapse-arrow"
          >
            <path
              class="a"
              d="M0,0,6,6l6-6"
              transform="translate(12.707 7.414) rotate(180)"
            />
          </svg>
          <ul class="course-list">
            <li class="course" v-for="course in myCourses" :key="course.id">
              <h5 class="name">{{ course.name }}</h5>
              <ul
                class="layouts"
                v-for="layout in course.layouts"
                :key="layout.id"
              >
                <li class="layout-name" @click.stop="selectLayout(layout)">
                  {{ layout.name }}
                </li>
              </ul>
              <p @click.stop="createNewLayout(course)">+ Create new layout</p>
            </li>
          </ul>
        </div>
      </section>
      <button @click="$router.go(-1)">Close</button>
    </div>
    <div
      class="editor"
      v-if="Object.keys(selectedLayout).length !== 0 && managerMode == 'update'"
    >
      <div class="info">
        <p>Location</p>
        <h5>{{ selectedLayout.course.name }}</h5>
        <p>Course layout</p>
        <h5 v-if="managerMode == 'update'">{{ selectedLayout.name }}</h5>
        <input
          type="text"
          placeholder="Layout name"
          v-model="selectedLayout.name"
          v-if="managerMode == 'createLayout'"
        />
        <span class="mobile-wrapper">
          <p>Par</p>
          <p class="fat">{{ viewedLayoutPar }}</p>
          <p>Holes</p>
          <p class="fat">{{ selectedLayout.latestVersion.holes.length }}</p>
        </span>
      </div>
      <div class="number">
        <p>Hole</p>
        <ul>
          <li v-for="hole in selectedLayout.latestVersion.holes" :key="hole.id">
            {{ hole.number }}
          </li>
        </ul>
      </div>
      <div class="name">
        <p>Custom name</p>
        <input
          type="text"
          maxlength="3"
          :placeholder="hole.number"
          :class="{ filled: hole.name != null && hole.name != '' }"
          v-for="hole in selectedLayout.latestVersion.holes"
          v-model="hole.name"
          :key="'name' + hole.id"
          @change="layoutChangeDetected()"
        />
      </div>
      <div class="par">
        <p>Par</p>
        <ul>
          <li
            v-for="hole in selectedLayout.latestVersion.holes"
            :key="'par' + hole.id"
          >
            <span class="decrease" @click.stop="decreasePar(hole)">-</span>
            <span>{{ hole.par }}</span>
            <span class="increase" @click.stop="increasePar(hole)">+</span>
          </li>
        </ul>
      </div>
      <div class="adjust">
        <p
          class="remove"
          @click.stop="removeHole(selectedLayout.latestVersion.holes)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <circle class="a" cx="10" cy="10" r="10" />
            <path
              class="b"
              d="M21388.969,3034.836h8"
              transform="translate(-21383 -3025)"
            />
          </svg>
          Remove hole
        </p>
        <p
          class="add"
          @click.stop="addHole(selectedLayout.latestVersion.holes)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <circle class="a" cx="10" cy="10" r="10" />
            <path
              class="b"
              d="M21388.969,3034.836h10"
              transform="translate(-21383.996 -3024.973)"
            />
            <path
              class="b"
              d="M0,0H10"
              transform="translate(9.975 14.863) rotate(-90)"
            /></svg
          >Add hole
        </p>
      </div>
      <div class="action">
        <button class="discard" @click.stop="loadData()" v-if="layoutChanged">
          Discard changes
        </button>
        <button class="discard faded" v-else>Discard changes</button>

        <button
          class="save"
          v-if="layoutChanged"
          @click.stop="updateLayoutVersion(selectedLayout)"
        >
          Update layout
        </button>
        <button class="save faded" v-else>Update layout</button>
      </div>
    </div>
    <div
      class="editor"
      v-if="managerMode == 'createLayout' || managerMode == 'createCourse'"
    >
      <div class="info">
        <p>Location</p>
        <h5 v-if="managerMode == 'createLayout'">
          {{ newLayout.course.name }}
        </h5>
        <input
          v-if="managerMode == 'createCourse'"
          type="text"
          placeholder="Location name"
          v-model="newLayout.course.name"
        />
        <p>Course layout</p>
        <input
          type="text"
          placeholder="Layout name"
          v-model="newLayout.layoutName"
        />
        <span class="mobile-wrapper">
          <p>Par</p>
          <p class="fat">{{ viewedLayoutPar }}</p>
          <p>Holes</p>
          <p class="fat">{{ newLayout.holes.length }}</p>
        </span>
      </div>
      <div class="number">
        <p>Hole</p>
        <ul>
          <li v-for="hole in newLayout.holes" :key="hole.id">
            {{ hole.number }}
          </li>
        </ul>
      </div>
      <div class="name">
        <p>Custom name</p>
        <input
          type="text"
          maxlength="3"
          :placeholder="hole.number"
          :class="{ filled: hole.name != null && hole.name != '' }"
          :key="'name' + hole.number"
          v-for="hole in newLayout.holes"
          v-model="hole.name"
        />
      </div>
      <div class="par">
        <p>Par</p>
        <ul>
          <li v-for="hole in newLayout.holes" :key="'par' + hole.number">
            <span class="decrease" @click.stop="decreasePar(hole)">-</span>
            <span>{{ hole.par }}</span>
            <span class="increase" @click.stop="increasePar(hole)">+</span>
          </li>
        </ul>
      </div>
      <div class="adjust">
        <p class="remove" @click.stop="removeHole(newLayout.holes)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <circle class="a" cx="10" cy="10" r="10" />
            <path
              class="b"
              d="M21388.969,3034.836h8"
              transform="translate(-21383 -3025)"
            />
          </svg>
          Remove hole
        </p>
        <p class="add" @click.stop="addHole(newLayout.holes)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <circle class="a" cx="10" cy="10" r="10" />
            <path
              class="b"
              d="M21388.969,3034.836h10"
              transform="translate(-21383.996 -3024.973)"
            />
            <path
              class="b"
              d="M0,0H10"
              transform="translate(9.975 14.863) rotate(-90)"
            /></svg
          >Add hole
        </p>
      </div>
      <div class="action" v-if="managerMode == 'createLayout'">
        <button class="discard" @click.stop="loadData()">Discard layout</button>
        <button
          class="save"
          v-if="newLayout.layoutName.length > 2"
          @click.stop="createLayout()"
        >
          Create layout
        </button>
        <button class="save faded" v-else>Create layout</button>
      </div>
      <div class="action" v-if="managerMode == 'createCourse'">
        <button class="discard" @click.stop="loadData()">Discard layout</button>
        <button
          class="save"
          v-if="
            newLayout.layoutName.length > 2 && newLayout.course.name.length > 2
          "
          @click.stop="createLocation()"
        >
          Create location
        </button>
        <button class="save faded" v-else>Create layout</button>
      </div>
    </div>
    <div class="footer" v-if="managerMode != 'createCourse'">
      <p>
        If the location is not in the list then create a new location with a new
        course layout
      </p>
      <div class="create-course" @click="createNewCourse()">
        + Create new location with course layout
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "CourseManager",
  directives: {
    ClickOutside,
  },
  data() {
    return {
      myCourses: [],
      loading: true,
      managerMode: "update",
      showCourseDropdown: false,
      layoutChanged: false,
      selectedLayout: {},
      newLayout: {
        course: {
          name: "",
          id: "",
        },
        layoutName: "",
        holes: [
          {
            number: 1,
            name: null,
            par: 3,
          },
          {
            number: 2,
            name: null,
            par: 3,
          },
          {
            number: 3,
            name: null,
            par: 3,
          },
          {
            number: 4,
            name: null,
            par: 3,
          },
          {
            number: 5,
            name: null,
            par: 3,
          },
          {
            number: 6,
            name: null,
            par: 3,
          },
          {
            number: 7,
            name: null,
            par: 3,
          },
          {
            number: 8,
            name: null,
            par: 3,
          },
          {
            number: 9,
            name: null,
            par: 3,
          },
          {
            number: 10,
            name: null,
            par: 3,
          },
          {
            number: 11,
            name: null,
            par: 3,
          },
          {
            number: 12,
            name: null,
            par: 3,
          },
          {
            number: 13,
            name: null,
            par: 3,
          },
          {
            number: 14,
            name: null,
            par: 3,
          },
          {
            number: 15,
            name: null,
            par: 3,
          },
          {
            number: 16,
            name: null,
            par: 3,
          },
          {
            number: 17,
            name: null,
            par: 3,
          },
          {
            number: 18,
            name: null,
            par: 3,
          },
        ],
      },
      selectedCourseNameFormatted: "Choose course layout",
    };
  },
  computed: {
    viewedLayoutPar() {
      if (this.managerMode == "update") {
        let par = 0;
        this.selectedLayout.latestVersion.holes.forEach((hole) => {
          par += hole.par;
        });
        return par;
      }
      if (
        this.managerMode == "createLayout" ||
        this.managerMode == "createCourse"
      ) {
        let par = 0;
        this.newLayout.holes.forEach((hole) => {
          par += hole.par;
        });
        return par;
      }
    },
  },
  methods: {
    layoutChangeDetected() {
      this.layoutChanged = true;
    },
    createNewCourse() {
      this.newLayout.course.name = "";
      this.newLayout.layoutName = "";
      this.selectedCourseNameFormatted = "+ Create new location";
      this.managerMode = "createCourse";
      this.showCourseDropdown = false;
    },
    async createLocation() {
      try {
        let newLocation = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation CreateCourse($Createcourse: CourseInput!){
            CreateCourse(input:$Createcourse){
              id
            }
          }
          `,
            variables: {
              Createcourse: {
                name: this.newLayout.course.name,
              },
            },
          },
        });

        this.newLayout.course.id = newLocation.data.data.CreateCourse.id;
        this.createLayout();
      } catch (error) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async createLayout() {
      let newHoles = this.newLayout.holes.map((hole) => {
        if (hole.name == null || hole.name == "") {
          delete hole.name;
        }

        delete hole.id;

        return hole;
      });

      try {
        let updatedLayout = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation Createlayout($Createlayout: LayoutInput!){
            CreateLayout(courseId:"${this.newLayout.course.id}", input:$Createlayout){
              id
            }
          }
          `,
            variables: {
              Createlayout: {
                name: this.newLayout.layoutName,
                holes: newHoles,
              },
            },
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });
        this.loadData();
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    createNewLayout(course) {
      this.newLayout.course.name = course.name;
      this.selectedCourseNameFormatted = course.name;
      this.newLayout.course.id = course.id;
      this.managerMode = "createLayout";
      this.showCourseDropdown = false;
    },
    increasePar(hole) {
      if (hole.par < 9) {
        hole.par++;
        this.layoutChanged = true;
      }
    },
    decreasePar(hole) {
      if (hole.par > 1) {
        hole.par--;
        this.layoutChanged = true;
      }
    },
    removeHole(holes) {
      if (holes.length > 3) {
        holes.splice(-1, 1);
        this.layoutChanged = true;
      }
    },
    addHole(holes) {
      let nextHole = {
        number: holes.length + 1,
        name: null,
        par: 3,
        id: "newHole" + holes.length + 1,
      };
      if (nextHole.number <= 36) {
        holes.push(nextHole);
        this.layoutChanged = true;
      }
    },
    selectLayout(layout) {
      this.showCourseDropdown = false;
      this.selectedLayout = layout;
      this.selectedCourseNameFormatted = layout.name;
      this.layoutChanged = false;
      this.managerMode = "update";
    },
    closeCourseSelector() {
      this.showCourseDropdown = false;
    },
    async updateLayoutVersion(layout) {
      let newHoles = layout.latestVersion.holes.map((hole) => {
        delete hole.id;
        if (hole.name == null || hole.name == "") {
          delete hole.name;
        }
        return hole;
      });

      try {
        let updatedLayout = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation CreateLayoutVersion($CreateLayoutVersion: CreateLayoutVersionInput!){
              CreateLayoutVersion(layoutId:"${layout.id}", input:$CreateLayoutVersion){
                id
                layout{
                  name
                  id
                  latestVersion{
                    holes{
                      id
                      number
                      par
                      name
                    }
                  }
                }
              }
            }
          `,
            variables: {
              CreateLayoutVersion: {
                holes: newHoles,
              },
            },
          },
        });

        this.loadData();
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async loadData() {
      let myCourses = await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            me{
              courses{
                name
                id
                layouts{
                  id
                  name
                  course{
                    name
                  }
                  latestVersion{
                    id
                    holes{
                      id
                      number
                      par
                      name
                    }
                  }
                }
              }
            }
          }
          `,
        },
      });

      this.myCourses = myCourses.data.data.me.courses;
      this.selectedLayout = {};
      this.showCourseDropdown = false;
      this.selectedCourseNameFormatted = "Select course layout";
      this.managerMode = "update";
      this.layoutChanged = false;
      this.loading = false;
      window.scrollTo(0, 0);
    },
  },
  mounted() {},
  created() {
    this.loadData();
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
a {
  color: $teal;
}

.course-manager {
  max-width: 1000px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "header"
    "selector"
    "editor"
    "footer";
  background-color: white;

  .header {
    grid-area: header;
    background-color: $blizzard;
    height: 50px;
    color: white;
    @include Gilroy-Bold;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
  }
  .selector {
    grid-area: selector;
    padding: 0px 15px 30px 15px;
    border-bottom: 1px solid $fog;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 44px;
    border-left: 1px solid $fog;
    border-right: 1px solid $fog;
    flex-direction: column;

    button {
      background-color: $twilight;
      color: white;
      border: 1px solid $twilight;
      font-size: 16px;
      border-radius: 6px;
      height: 48px;
      padding: 0 20px;
      width: 100%;
    }

    section {
      width: 100%;
      display: flex;
      flex-direction: column;

      h5 {
        font-size: 16px;
        margin: 0;
        @include Gilroy-Bold;
      }
    }

    .course-selector {
      margin: 20px 0;
      height: 48px;
      border: 1px solid #b4b6c2;
      width: 100%;
      border-radius: 6px;
      justify-content: space-between;
      align-items: center;
      display: flex;
      padding: 0 15px;
      position: relative;
      cursor: pointer;
      p {
        margin: 0;
      }

      &.disabled {
        svg {
          display: none;
        }
        height: auto;
        border: 0px solid transparent;
        padding: 0;
        margin-bottom: 46px;
      }

      > svg {
        width: 12px;
        margin-left: 15px;
        transition: all ease 0.3s;
        path {
          stroke: $midnight;
          stroke-width: 2px;
          fill: none;
        }
      }
      &.expanded {
        .course-list {
          max-height: 60vh;
          transition: 0.3s;
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }
        > svg {
          transform: rotate(180deg);
        }
      }

      .course-list {
        position: absolute;
        width: 100%;
        top: 60px;
        left: 0;
        padding: 0;
        background-color: #f5f5f6;
        z-index: 10;
        list-style: none;
        margin: 0;
        max-height: 0px;
        overflow: hidden;
        transition: 0.3s;
        cursor: default;

        .course {
          border-bottom: 1px solid #e7e9ec;
          padding: 15px 0;

          p {
            padding: 0px 15px;
            color: $ocean;
          }
        }

        .name {
          font-size: 12px;
          @include Gilroy-Black;
          padding: 0;
          padding-left: 15px;
          text-transform: uppercase;
          margin: 0;
          cursor: default;

          input {
            &::placeholder {
              color: $strom;
            }

            &.filled {
              background-color: $midnight;
              @include Gilroy-Bold;
              border: 1px solid $midnight;
              color: white;
            }
          }
        }
        .layouts {
          margin-top: 10px;
          list-style: none;
          margin: 0;
          padding: 0;

          .layout-name {
            cursor: pointer;
            font-size: 14px;
            height: 34px;
            display: flex;
            align-items: center;
            padding-left: 15px;
            &:hover {
              background-color: #e7e9ec;
            }
          }
        }
      }
    }
  }
  .editor {
    grid-area: editor;
    padding: 30px 15px;
    border-left: 1px solid $fog;
    border-right: 1px solid $fog;
    display: grid;
    grid-template-columns: 1fr 3fr 2fr;
    grid-template-rows: auto;
    grid-template-areas:
      "info info info"
      "number name par"
      "adjust adjust adjust"
      "action action action";
    background-color: white;
    font-size: 16px;
    @include Gilroy-Regular;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include Gilroy-Bold;
      }
    }

    .fat {
      @include Gilroy-Bold;
    }

    .info {
      grid-area: info;

      h5 {
        font-size: 16px;
        @include Gilroy-Bold;
        margin-bottom: 25px;
        margin-top: 5px;
      }
      p {
        margin-bottom: 0px;

        &.fat {
          margin-bottom: 15px;
        }
      }

      .mobile-wrapper {
        display: flex;
        margin: 15px 0;
        p {
          margin-right: 5px;
          &.fat {
            margin-right: 30px;
          }
        }
      }

      input {
        width: 100%;
        height: 50px;
        border: 1px solid $blizzard;
        border-radius: 6px;
        padding-left: 15px;
        margin-top: 10px;
        margin-bottom: 25px;
        outline: none;
        transition: all ease 0.3s;

        &:focus {
          &::placeholder {
            opacity: 0;
            transition: all ease 0.3s;
          }
        }

        &::placeholder {
          color: $blizzard;
        }
      }
    }
    .number {
      grid-area: number;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      ul {
        li {
          width: 31px;
          text-align: center;
        }
      }
      p {
        text-align: center;
      }
    }
    .name {
      grid-area: name;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      input {
        height: 34px;
        margin: 3px 0;
        width: 64px;
        border-radius: 6px;
        border: 1px solid $blizzard;
        text-align: center;
        outline: none;
        transition: all ease 0.3s;

        &:focus {
          &::placeholder {
            opacity: 0;
            transition: all ease 0.3s;
          }
        }
        &.filled {
          background-color: $midnight;
          color: white;
          border: 1px solid $midnight;
          outline: none;
          @include Gilroy-Bold;
        }
      }
      p {
        text-align: center;
      }
    }
    .par {
      grid-area: par;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;

      ul {
        width: 100%;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
        }
      }

      .increase,
      .decrease {
        color: $strom;
        @include Gilroy-Regular;
        font-size: 24px;
      }
      span {
        width: 35px;
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
    .adjust {
      grid-area: adjust;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 35px;

      .remove {
        color: $dusk;
        margin-right: 23px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }
        .a {
          fill: #ff0064;
        }
        .b {
          fill: none;
          stroke: #fff;
          stroke-width: 2px;
        }
      }
      .add {
        color: $grass;
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        .a {
          fill: #00d098;
        }
        .b {
          fill: none;
          stroke: #fff;
          stroke-width: 2px;
        }
      }
    }
    .action {
      grid-area: action;
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 45px;
      flex-direction: column;

      .discard {
        background-color: white;
        border-radius: 6px;
        border: 1px solid $twilight;
        color: $twilight;
        padding: 0 20px;
        height: 50px;
        width: 100%;
        margin-bottom: 15px;

        &.faded {
          border: 1px solid $fog;
          background-color: white;
          color: $fog;
        }
      }
      .save {
        background-color: $twilight;
        border-radius: 6px;
        border: 1px solid $twilight;
        color: white;
        padding: 0 20px;
        height: 50px;
        width: 100%;

        &.faded {
          border: 1px solid $sleet;
          background-color: $sleet;
          color: white;
        }
      }
    }
  }
  .footer {
    grid-area: "footer";
    padding: 30px 30px 60px 30px;
    border-left: 1px solid $fog;
    border-bottom: 1px solid $fog;
    border-right: 1px solid $fog;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid $fog;

    .create-course {
      color: $teal;
      cursor: pointer;
    }

    p {
      max-width: 310px;
      line-height: 1.3em;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .course-manager {
    max-width: 1000px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      "header"
      "selector"
      "editor"
      "footer";
    background-color: white;

    .header {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .selector {
      flex-direction: row;
      padding: 30px 30px;
      section {
        width: 310px;
      }
      button {
        background-color: $twilight;
        color: white;
        border: 1px solid $twilight;
        font-size: 16px;
        border-radius: 6px;
        height: 48px;
        padding: 0 20px;
        display: inline-flex;
        width: auto;
      }

      section {
        width: 50%;
      }
      .course-selector {
        width: 310px;
      }
    }
    .editor {
      grid-area: editor;
      padding: 30px 30px;
      border-left: 1px solid $fog;
      border-right: 1px solid $fog;
      display: grid;
      grid-template-columns: calc(100% - 450px) 125px 200px 125px;
      grid-template-rows: auto;
      grid-template-areas:
        "info number name par"
        "info adjust adjust adjust"
        "info action action action";
      background-color: white;
      font-size: 16px;
      @include Gilroy-Regular;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include Gilroy-Bold;
        }
      }

      .mobile-wrapper {
        display: flex;
        flex-direction: column;
      }

      .fat {
        @include Gilroy-Bold;
      }

      .info {
        grid-area: info;
        h5 {
          font-size: 16px;
          @include Gilroy-Bold;
          margin-bottom: 25px;
          margin-top: 5px;
        }

        p {
          margin-bottom: 0px;

          &.fat {
            margin-bottom: 15px;
          }
        }

        input {
          width: 310px;
          height: 50px;
          border: 1px solid $blizzard;
          border-radius: 6px;
          padding-left: 20px;
          margin-top: 10px;
          margin-bottom: 25px;
          outline: none;
          transition: all ease 0.3s;

          &:focus {
            &::placeholder {
              opacity: 0;
              transition: all ease 0.3s;
            }
          }

          &::placeholder {
            color: $blizzard;
          }
        }
      }
      .number {
        grid-area: number;
        align-items: center;
        width: 100%;
        ul {
          width: 100%;
          li {
            width: 100%;
          }
        }
        p {
          text-align: center;
        }
      }
      .name {
        grid-area: name;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        input {
          height: 34px;
          margin: 3px 0;
          width: 64px;
          border-radius: 6px;
          border: 1px solid $blizzard;
          text-align: center;
          outline: none;
          transition: all ease 0.3s;

          &:focus {
            &::placeholder {
              opacity: 0;
              transition: all ease 0.3s;
            }
          }
          &.filled {
            background-color: $midnight;
            color: white;
            border: 1px solid $midnight;
            outline: none;
            @include Gilroy-Bold;
          }
        }
        p {
          text-align: center;
        }
      }
      .par {
        grid-area: par;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        ul {
          width: 100%;
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
          }
        }

        .increase,
        .decrease {
          color: $strom;
          @include Gilroy-Regular;
          font-size: 24px;
        }
        span {
          width: 35px;
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
      .adjust {
        grid-area: adjust;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 35px;
        padding-left: 47px;
        .remove {
          color: $dusk;
          margin-right: 23px;
          display: flex;
          align-items: center;

          svg {
            margin-right: 10px;
          }
          .a {
            fill: #ff0064;
          }
          .b {
            fill: none;
            stroke: #fff;
            stroke-width: 2px;
          }
        }
        .add {
          color: $grass;
          display: flex;
          align-items: center;

          svg {
            margin-right: 10px;
          }

          .a {
            fill: #00d098;
          }
          .b {
            fill: none;
            stroke: #fff;
            stroke-width: 2px;
          }
        }
      }
      .action {
        grid-area: action;
        padding-left: 47px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 45px;
        flex-direction: row;
        .discard {
          background-color: white;
          border-radius: 6px;
          border: 1px solid $twilight;
          color: $twilight;
          padding: 0 20px;
          height: 50px;
          width: auto;
          margin-bottom: 0;
        }
        .save {
          background-color: $twilight;
          border-radius: 6px;
          border: 1px solid $twilight;
          color: white;
          padding: 0 20px;
          height: 50px;
          width: auto;
        }
      }
    }
    .footer {
      grid-area: "footer";
      padding: 30px 30px 60px 30px;
      border-left: 1px solid $fog;
      border-bottom: 1px solid $fog;
      border-right: 1px solid $fog;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top: 1px solid $fog;

      p {
        max-width: 310px;
        line-height: 1.3em;
      }
    }
  }
}
</style>
